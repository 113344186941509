import React from "react"
import {urlGuidSearch, stringReplaceMask} from 'helper-func'

function App() {

    document.title = "Инструкция"

    let url = stringReplaceMask(urlGuidSearch(3), 'url')
    let urlMatch = (['windows', 'android', 'macos']).indexOf(url)

    let textOs = [
        {license: 'Лицензия отсутствует', find: '«Ключ» и нажать на крестик'},
        {license: 'Подписка не активирована', find: '«Ключ» и нажать на крестик'},
        {license: 'Программа не активирована', find: '«Статус» и нажать на крестик'}
    ]

    return (
        <div className="App">

            <div className="MainPage__width MainPage__background">
                <div className="MainPage__logoBlock"><a target="_blank" href="https://lk.rt.ru/"><div className="partnerLogo" /></a></div>
                <div className="contentBlock">
                    <div className="MainPage__titleBlock">
                        <div className="MainPage__titleBlock-title">В приложении ошибка<br />«Срок действия лицензии истек»</div>
                        <div className="MainPage__titleBlock-background">
                            <div className="product_kas1" />
                            <div className="product_kas3" />
                            <div className="product_kas2" />
                        </div>
                    </div>

                    {
                        urlMatch === -1 ? (
                            <div className="MainPage__centerBlock">

                                <div className="MainPage__centerBlock-lines">
                                    <div>
                                        <div className="MainPage__bottomBlock-nextIcon" />
                                    </div>
                                    <div className="MainPage__centerBlock-right">
                                        Зайдите в Личный кабинет абонента Ростелеком, проверьте баланс и статус подписки
                                    </div>
                                </div><br />

                                <div className="MainPage__centerBlock-lines">
                                    <div>
                                        <div className="MainPage__bottomBlock-nextIcon" />
                                    </div>
                                    <div className="MainPage__centerBlock-right">
                                        Баланс положительный и подписка активна
                                        <div className="MainPage__centerBlock-lines MainPage__centerBlock-linesPaddingTop">
                                            <div><div className="MainPage__bottomBlock-handIcon" /></div>
                                            <div className="MainPage__centerBlock-linesSmall">Выберите систему своего устройства</div>
                                        </div>
                                        <div className="MainPage__product-osLinks">
                                            <a href="/instruction/expired_index/windows"><div className="MainPage__product-osLink"><div className="MainPage__product-whiteIcon-windows" /><div className="MainPage__product-osText">Windows</div></div></a>
                                            <a href="/instruction/expired_index/macos"><div className="MainPage__product-osLink"><div className="MainPage__product-whiteIcon-mac" /><div className="MainPage__product-osText">Mac OS</div></div></a>
                                        </div>
                                    </div>
                                </div><br /><br />

                                <div className="MainPage__centerBlock-lines">
                                    <div>
                                        <div className="MainPage__bottomBlock-nextIcon" />
                                    </div>
                                    <div className="MainPage__centerBlock-right">
                                        Баланс отрицательный, зайдите в Личный Кабинет абонента Ростелеком и пополните счёт
                                    </div>
                                </div>

                            </div>
                        ) : (
                            <div className="MainPage__centerBlock">

                                <div className="MainPage__bottomBlock-back">
                                    <div className="MainPage__bottomBlock-nextIcon backIcon" />
                                    <a className="MainPage__bottomBlock-nextText" href="/instruction/expired_index">Вернуться</a>
                                </div>

                                {
                                    (urlMatch > -1 && urlMatch < 3) && (
                                        <div className="MainPage__bottomBlock-grey">
                                            <div className="">
                                                <div className="MainPage__heplBlock-variant">Вариант 1</div>
                                                <div className="MainPage__heplBlock-variantPoint">
                                                    1. В окне программы нажать на «{textOs[urlMatch].license}»<br />
                                                    2. Нажать на кнопку «Проверить статус подписки». Подписка активирована<br />
                                                    * Если Вы видите сообщение «Подписка истекла. Пожалуйста, приобретите новую лицензию», перейдите к варианту 2.
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="MainPage__heplBlock-variant">Вариант 2</div>
                                                <div className="MainPage__heplBlock-variantPoint">
                                                    1. В окне программы нажать на «{textOs[urlMatch].license}»<br />
                                                    2. Найти строку {textOs[urlMatch].find}<br />
                                                    3. В появившемся окне нажать на кнопку «Удалить»<br />
                                                    4. В окне программы нажать на кнопку «Ввести код активации»<br />
                                                    5. Ввести код активации и нажать кнопку «Активировать». Подписка активирована<br />
                                                    * Если Вы видите сообщение «Подписка истекла. Пожалуйста, приобретите новую лицензию», обратитесь в службу поддержки Вашего интернет - провайдера.
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                        )
                    }

                    <div className="MainPage__bottomBlock">
                        <div className="MainPage__bottomBlock-ask">Остались вопросы?</div>
                        <div className="MainPage__bottomBlock-point">
                            <div className="MainPage__bottomBlock-lupaIcon" />
                            <div className="">Изучите <a className="font18" target="_blank" href="https://support.kaspersky.ru/#s_tab2"><u>раздел Поддержка</u></a></div>
                        </div>
                        <div className="MainPage__bottomBlock-point">
                            <div className="MainPage__bottomBlock-phoneIcon" />
                            <div className="">Позвоните нам: <div className="font18">8-800-700-88-11</div></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default App;
