import React, {useState} from "react"
import {urlGuidSearch, stringReplaceMask} from 'helper-func'

function App() {

    document.title = "Инструкция"

    let showPage = false
    let url = stringReplaceMask(urlGuidSearch(2), 'url')
    if ((['kav', 'kis', 'kids', 'drweb', 'kis_mobile', 'nod32', 'eset_mobile']).indexOf(url) > -1) {
        showPage = true
    }

    let arrScreen = {
        kav: [
            {text: 'Запустите файл установки. В появившемся окне нажмите кнопку «Продолжить»'},
            {text: 'Нажмите кнопку «Установить».'},
            {text: 'Подтвердите согласие с условиями Лицензионного соглашения.'},
            {text: 'Подтвердите согласие с Положением о Kaspersky Security Network.'},
            {text: 'Дождитесь завершения установки программы.'},
            {text: 'После завершения установки программы нажмите кнопку «Завершить».'},
            {text: 'Дождитесь запуска программы.'},
            {text: 'Для активации продукта введите лицензионный ключ.'},
            {text: 'Нажмите кнопку «Активировать».'},
            {text: 'Дождитесь получения данных с сервера.'},
            {text: 'Для регистрации продукта введите свой e-mail и нажмите "Зарегистрировать и продолжить".'},
            {text: 'Нажмите кнопку «Завершить». Антивирус установлен и активирован.'},
        ],
        kis: [
            {text: 'Запустите скачанную программу и нажмите «Установить».'},
            {text: 'Подтвердите согласие с условиями Лицензионного соглашения.'},
            {text: 'Подтвердите согласие с Положением о Kaspersky Security Network.'},
            {text: 'Дождитесь пока программа установится.'},
            {text: 'Завершите установку нажав кнопку "Готово"'},
            {text: 'Скопируйте или введите ключ, затем нажмите далее и готово.'},
        ],
        kids: [
            {text: 'В окне Kaspersky Safe Kids нажмите на кнопку Установить. '},
            {text: 'Дождитесь окончания установки и нажмите на кнопку Завершить.'},
            {text: 'Чтобы использовать Kaspersky Safe Kids, вам нужно подключить программу к вашей учетной записи веб-портала My Kaspersky: Если у вас уже есть учетная запись My Kaspersky, введите логин и пароль и нажмите на кнопку Войти. Если у вас нет учетной записи, нажмите на ссылку Создать учетную запись прямо сейчас'},
            {text: 'В окне Создание учетной записи заполните поля и нажмите на кнопку Создать.'},
            {text: 'Проверьте вашу электронную почту и активируйте учетную запись, пройдя по ссылке в письме. В окне Kaspersky Safe Kids нажмите на кнопку Готово.'},
            {text: 'Создайте профиль ребенка: укажите имя, год рождения, добавьте фотографию и нажмите на кнопку Продолжить.'},
            {text: 'Укажите учетную запись ребенка на комьютере или создайте новую.'},
            {text: 'Задайте имя и пароль к учетной записи. Нажмите на кнопку Продолжить'},
            {text: 'Нажмите на кнопку Продолжить. Нажмите на кнопку Завершить. Научите ребенка заходить на компьютер под своей учетной записью или выполните вход под учетной записью ребенка самостоятельно. Теперь ребенок защищен и контролируется в соответствии с возрастом, который вы указали в программе.'},
        ],
        drweb: [
            {text: 'Запустите файл установки. В появившемся окне отметьте галочку «У меня на компьютере нет других антивирусов».\n'},
            {text: 'Нажмите кнопку «Далее».'},
            {text: 'Отметьте пункт "Я принимаю условия данного лицензионного соглашения" для подтверждения согласия и нажмите "Далее".'},
            {text: 'Нажмите кнопку «Установить».'},
            {text: 'Дождитесь окончания установки программы.'},
            {text: 'Нажмите кнопку «Завершить». Антивирус установлен и активирован.'},
        ],
        kis_mobile: [
            {text: 'Запустите скачанную программу и нажмите «Установить».'},
            {text: 'Дождитесь установки программы.'},
            {text: 'Нажмите кнопку "Открыть".'},
            {text: 'Выберите вашу страну и нажмите кнопку "Далее".'},
            {text: 'Подтвердите согласие с условиями Лицензионного соглашения.'},
            {text: 'Скопируйте или введите ключ, затем нажмите "Продолжить".'},
        ],
        nod32: [
            {text: 'Запустите файл установки. В появившемся окне нажмите кнопку «Далее».'},
            {text: 'Подтвердите согласие с условиями Лицензионного соглашения, выбрав пункт «Я принимаю условия Лицензионного соглашения» и нажмите кнопку «Далее».'},
            {text: 'В новом окне нажмите кнопку «Далее».'},
            {text: 'Затем нажмите кнопку «Установить».'},
            {text: 'Дождитесь завершения устанвоки.'},
            {text: 'Нажмите кнопку «Готово» для завершения. Антивирус установлен и активирован.'},
            {text: 'Нажмите далее и готово.'},
            {text: 'Программа установлена и активирована.'},
        ],
        eset_mobile: [
            {text: 'Запустите файл установки. В появившемся окне нажмите кнопку «Установить».'},
            {text: 'Дождитесь завершения установки.'},
            {text: 'Нажмите кнопку "Открыть".'},
            {text: 'Подтвердите согласие с &nbsp;условиями Лицензионного соглашения, нажав кнопку "Я&nbsp;принимаю"'},
            {text: 'Нажмите кнопку "Далее".'},
            {text: 'Нажмите кнопку "Далее".'},
            {text: 'Дождитесь завершения проверки лицензии.'},
            {text: 'Нажмите кнопку "Начать пробное использовние".'},
            {text: 'Нажмите кнопку «Готово».'},
            {text: 'Запустите антивирус и в меню выберите пункт "Лицензия".'},
            {text: 'Выберите "Активация приложения"'},
            {text: 'Введите имя пользователя и пароль и нажмите кнопку "Активировать".'},
        ],
    }

    const [activeKey, setActiveKey] = useState(0)
    const [screenPos, setScreenPos] = useState('0px')

    function setActiveKeyFunc (i) {
        if (i > -3 || i < 6) {

            if (i === -1) {
                if (activeKey > 0) {
                    i = activeKey - 1
                } else {
                    i = 0
                }
            } else  if (i === -2) {
                if (activeKey < arrScreen[url].length - 1) {
                    i = activeKey + 1
                } else {
                    i = arrScreen[url].length - 1
                }
            }

            setActiveKey(i)
            setScreenPos((-1 * 522 * i) + 'px')
        }
    }

    return (
        <div className="App">

            {
                !showPage ? <div className="notFound">404</div> : <div className="MainPage__width MainPage__background MainPage__pageHelp">
                    <div className="contentBlock">
                        <div className="MainPage__centerBlockHelp">

                            <div className="MainPage__centerBlock-alert">
                                <div className="MainPage__centerBlock-alertField MainPage__alert-icon" />
                                <div className="MainPage__centerBlock-alertField">Если у вас установлен антивирус, необходимо удалить старый дистрибутив и начать установку нового дистрибутива.</div>
                            </div>

                            <div className="MainPage__centerBlock-alertTitle">
                                Установка антивирусной защиты
                            </div>

                            <div className="MainPage__centerBlock-alertKeys">
                                <div className={(activeKey === 0 ? 'MainPage__helpIcons-opacity' : '')} key={'-1'} onClick={e => setActiveKeyFunc(-1)}>←</div>
                                {
                                    arrScreen[url].map((item, i) => {
                                        return <div key={i} onClick={e => setActiveKeyFunc(i)} className={"" + ((activeKey === i) && 'MainPage__centerBlock-alertKeysActive')}>{i+  1}</div>
                                    })
                                }
                                <div className={(activeKey === arrScreen[url].length - 1 ? 'MainPage__helpIcons-opacity' : '')} key={'-2'} onClick={e => setActiveKeyFunc(-2)}>→</div>
                            </div>

                            <div className="MainPage__centerBlock-alertScreen">
                                {(!!arrScreen[url] && !!arrScreen[url][activeKey]) && arrScreen[url][activeKey].text}<br /><br />
                                <div className="MainPage__centerBlock-alertScreenBlock" style={{zoom: '1.0', left: screenPos, transitionDuration: '0.33s', transform: 'translate3d(0px, 0px, 0px)'}}>
                                    {
                                        arrScreen[url].map((item, i) => {
                                            return (<div className={'MainPage__helpScreen MainPage__helpIcons-' + url + (i + 1)} key={i} />)
                                        })
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default App;
